<template>
  <div id="HomeGridProducts" class="tanajil-tabs">
    <div class="container">
      <h3 class="custommenu-title-blog">
        Ultimos agregados
      </h3>
      <div v-if="(products !== undefined && products !== null && products.length == 0)">
        <h3 class="text-center">
          <strong>No se encontró ningun producto</strong>
        </h3>
      </div>
      <div v-else class="tab-container tab-container-6">
        <div id="all" class="tab-panel">
          <div class="tanajil-product">
            <ul class="row list-products auto-clear equal-container product-grid">
              <li v-for="product in products" :key="product.id"
                class="product-item  col-lg-3 col-md-4 col-sm-6 col-xs-6 col-ts-12 style-1">
                <ProductCard :product="product" />
              </li>
            </ul>
          </div>
        </div>
        <div id="bestseller" class="tab-panel active">
          <div class="tanajil-product">
            <ul
              class="row list-products auto-clear equal-container product-grid">
              <li v-for="product in products" :key="product.id"
                class="product-item  col-lg-3 col-md-4 col-sm-6 col-xs-6 col-ts-12 style-1">
                <ProductCard :product="product" />
              </li>
            </ul>
          </div>
        </div>
        <div id="new_arrivals" class="tab-panel">
          <div class="tanajil-product">
            <ul class="row list-products auto-clear equal-container product-grid">
              <li v-for="product in products" :key="product.id"
                class="product-item  col-lg-3 col-md-4 col-sm-6 col-xs-6 col-ts-12 style-1">
                <ProductCard :product="product" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <GridPagination/>
    </div>
  </div>
</template>

<script>
import ProductCard from "./Product-card";
import GridPagination from "../components/gridProducts/GridPagination.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  components: {
    ProductCard,
    GridPagination,
  },

  data() {
    return {
      isFirstCharged:true,
    };
  },
  created() {
    this.newProducts();
  },
  beforeDestroy() {
      this.setPageNumber(1);
      this.setSortMethod('abc');
      this.setProducts([]);
  },
  computed: {
    ...mapGetters({
      products: 'ecommerce/products'
    })
  },
  methods: {
    ...mapActions({
      loadProducts: 'ecommerce/loadProducts'
    }),
    ...mapMutations({
      setPageNumber: 'ecommerce/SET_PAGE_NUMBER',
      setProducts: 'ecommerce/SET_PRODUCTS',
      setSortMethod: 'ecommerce/SET_SORT_METHOD'
    }),
    newProducts(){
      this.setSortMethod('new');
      this.loadPageOne();
    },
    all(){
      this.setSortMethod('abc');
      this.loadPageOne();
    },
    loadPageOne(){
      this.setPageNumber(1);
      this.loadProducts();
    }
  }
};
</script>

<style></style>
